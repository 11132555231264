import logo1 from './logo.svg';
import logo from './assets/theme_asset/logo.png';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import Modal from "./modal";
import { Link } from 'react-router-dom'; // Import the Link component
import auth6 from './assets/theme_asset/authentication/6.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';



// Assuming you're using React Router and have a route set up for blog details

// Your other imports...

const MySwal = withReactContent(Swal);

function Register() {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [data, setData] = useState(null);
  const [inputEmail, setInputEmail] = useState('');


  const location = useLocation();


  
  // Function component definition

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_code: '',
    mobile: '',
    refferalid: '',
    upiid: '',
    password: '',
  });

  const fetchData = async (refferalid) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/userpageapi`);
      const userData = response.data.user;
      setData(response.data);
      let found = false;
     
      // Loop through each user object in the userData array
      userData.forEach(user => {
        // Check if the user's email matches the referral id
        if (user.email === refferalid) {
          found = true;
        }
      });
  
      if (found) {
        MySwal.fire({
          icon: 'success',
          title: 'Referral ID Found',
          text: 'This referral ID exists in the database!',
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Referral ID Not Found',
          text: 'This referral ID does not exist in the database!',
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to check referral ID. Please try again later.',
      });
    }
  };
  
  const [emailFromURL, setEmailFromURL] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
  
    if (email) {
      const decodedEmail = decodeURIComponent(email);
      setEmailFromURL(decodedEmail);
      fetchData(decodedEmail);
    } else {
      setEmailFromURL(inputEmail);

    }
  }, [location.search, inputEmail]);
  
 
  const handleReferral = (e) => {
    const { name, value } = e.target;

    if (name === 'refferalid') {
      fetchData(value); // Call fetchData only when referral ID changes
    }

    setFormData({ ...formData, [name]: value });
};

const handleChange = (e) => {
    const { name, value } = e.target;
 
    if (name === 'refferalid') {

      setFormData({ ...formData, [name]: value });
  } else {
      setFormData({ ...formData, [name]: value });
  }

    setFormData({ ...formData, [name]: value });
};
useEffect(() => {
  // Trigger handleChange when emailFromURL changes
  handleChange({ target: { name: 'refferalid', value: emailFromURL } });
}, [emailFromURL]);


  // Function to submit the form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      const response = await axios.post(`${apiBaseUrl}/register`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data for file upload
        },
      });
      const token = response.data.token;
      const id = response.data.user_id;
      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
      navigate('/'); // Redirect the user to the homepage after successful registration
    } catch (error) {
      setModalMessage({
        title: 'Error',
        content: 'Failed to register. Please try again later or check the information you filled.',
      });
      setShowModal(true);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Function to check the referral ID against the database
  
  
  
  return (

    <body className="">
    
    <div>
       {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       <div className="auth-header">
       <div className="custom-container">
       <div className='row'> 
          <div className='col-2'>
        <Link to="/login" style={{ textDecoration: 'none',marginLeft:'10px',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 100px', fontSize:'15px',color:'white'}}><img src={logo} alt='logo' style={{width:'55px', height:'45px', marginLeft:'5px' }}/><br /><b>BBN Play</b></div>
    </div></div>
    <div className="auth-content">
      <div>
        <h2>Welcome to BBN Play</h2>
        <h4 className="p-0">Fill up the form</h4>
      </div>
    </div>
  </div>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form" onSubmit={handleSubmit}>
  <div className="custom-container">
  <div className="form-group">
        <label htmlFor="Refferal ID" className="form-label">
          Refferal ID
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="refferalid"
            name = "refferalid"
            value={emailFromURL} 
            placeholder="Enter Your Refferal Id"
            onChange={handleChange}
            onBlur={handleReferral}
          />
        </div>
      </div>
    
      <div className="form-group">
        <label htmlFor="name" className="form-label">
          Full name
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Enter your name"
            onChange={handleChange}
          />
        </div>
      </div>
     
      <div className="form-group">
        <label htmlFor="Mobile" className="form-label">
          Mobile
        </label>
        <div className='row'>
          <div className='col-3'> 
          <div className="form-input">
        
          <select
            className="form-control"
            id="mobile_code"
            name="mobile_code"
            onChange={handleChange}
          >
  <option value="">Select Mobile Code</option>
  {data && data.country && data.country.map((country) => (
   <option key={country.id} value={country.phonecode}>{country.iso} ({country.phonecode})</option>
  ))}
</select>

      </div>
      </div>
      <div className='col-9'> 
        <div className="form-input">
        
          <input
            type="text"
            className="form-control"
            id="mobile"
            name="mobile"
            placeholder="Enter your Mobile"
            onChange={handleChange}
          />
        </div>
        </div>
        </div>
      </div>
      {/* <div className="form-group">
        <label htmlFor="image" className="form-label">
          Profile Image
        </label>
        <div className="form-input">
          <input
            type="file"
            className="form-control"
            id="image"
            name ="image"
            onChange={handleChange}
          />
        </div>
      </div> */}
      <div className="form-group">
        <label htmlFor="email" className="form-label">
          Email id
        </label>
        <div className="form-input">
          <input
            type="email"
            className="form-control"
            id="email"
            name = "email"
            placeholder="Enter Your Email"
            onChange={handleChange}
          />
        </div>
      </div>
      
      <div className="form-group">
        <label htmlFor="UPI ID" className="form-label">
          BBN Address
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="upiid"
            name = "upiid"
            placeholder="Enter Your BBN Address"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="newpin" className="form-label">
          Password
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="password"
            name="password"
            placeholder="Enter Password"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="newpin" className="form-label">
          Confirm Password
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="con_password"
            name="con_password"
            placeholder="Confirm Password"
            required
          />
        </div>
      </div>
      {/* <div className="form-group">
        <label htmlFor="confirmpassword" className="form-label">
          Re-enter new Password
        </label>
        <div className="form-input">
          <input
            type="number"
            className="form-control"
            id="confirmpin"
            placeholder="Re-enter Password"
          />
        </div>
      </div> */}
      <div className="remember-option mt-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            I agree to all terms &amp; condition
          </label>
        </div>
      </div>
      <button type='submit' className="btn theme-btn w-100">
        Sign up
      </button>
      <h4 className="signup">
        Already have an account ?<Link to="/login"> Sign in</Link>
      </h4>
    </div>
  </form>
       
        
      </div>
      </body>
  );
}

export default Register;
