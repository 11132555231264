import logo1 from './logo.svg';

import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import big from './assets/theme_asset/token/big.png';
import small from './assets/theme_asset/token/small.png';

import countdown from './assets/theme_asset/countdown.mp3';

import back from './assets/theme_asset/back.png';

import { BiSolidLeftArrow } from 'react-icons/bi';


import './game.css';

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';


import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import win from './assets/theme_asset/win.png';

import './game.css';

const WinPopup2 = () => {
    const [showWinImage, setShowWinImage] = useState(false);
    const [data, setData] = useState('');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;


    const fetchData = async () => {
        try {
          
      
          const id = localStorage.getItem('id');
          const response = await axios.get(`${apiBaseUrl}/gamepageapi/${id}`);
          setData(response.data);
       
      
         
        } catch (error) {
        } finally {
        }
      };

    useEffect(() => {
        // Logic to show win image and set timeout to hide it
        const showImage = () => {
            setShowWinImage(true);
            const imageTimeout = setTimeout(() => {
                setShowWinImage(false);
            }, 3000);
            return () => clearTimeout(imageTimeout);

            
        };

        // Call showImage when the user wins
        // Add your logic to determine win condition here
        const userWins = true; // For demonstration, replace with actual logic
        if (userWins) {
            showImage();
        }
        fetchData();
    }, []);

    return (
        <>
            {showWinImage && (
                <div className="win-popup-overlay">
                    <div className="win-popup">
                        <img src={win} alt='png' style={{ width: '350px' }} />
                        <div style={{ margin: '-250px 0 0 150px', color: 'white' }}>{data.winnerData3Min?.winning_amount} BBN</div>
                        <div style={{ margin: '92px 0 0 75px', color: 'white' }}>{data.winnerData3Min?.lastSlot.bigsmall.toUpperCase()}</div>
                        <div style={{ margin: '-23px 0 0 245px', color: 'white' }}>{data.winnerData3Min?.lastSlot.color.toUpperCase()}</div>
                        <div style={{ margin: '31px 0 0 170px', color: 'white' }}>{data.winnerData3Min?.lastSlot.no}</div>

                    </div>
                </div>
            )}
        </>
    );
};

export default WinPopup2;
