import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';


import './App.css';

import logo1 from './assets/theme_asset/logo/logo.png';
import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import home_bg from './assets/theme_asset/background/home-bg.jpg';

import logo from './assets/theme_asset/logo.png';

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [bbnPrice, setBbnPrice] = useState(null);
  const fetchData = async () => {
    try {
      
      // const response1 = await axios.get(`http://139.59.171.227:5555/getprice`);

      // console.log(response1.data);

      // setBbnPrice(response1.data);

    } catch (error) {

    } finally {

    }
  };
  useEffect(() => {
    // Check if token exists
    const token = localStorage.getItem('token');
    if (!token) {
      // If no token exists, navigate to login
      navigate('/login');
    }

    // Replace icons after component is mounted
    feather.replace();
    fetchData();
    
  }, []);


  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };
  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
          
  return (
    <div className=''>
      <div className={`offcanvas sidebar-offcanvas offcanvas-start${isSidebarOpen ? ' show' : ''}`} tabIndex={-1} id="offcanvasLeft">
          <div className="offcanvas-header sidebar-header">
          <a  onClick={closeSidebar}>
            <i className="categories-icon"  data-feather="x" />
                  </a>

            <div className="sidebar-logo">
              <img className="img-fluid logo" src={logo} alt="logo" />
              
            </div>
             
            <div className="balance">
              <img className="img-fluid balance-bg" src={home_bg} alt="auth-bg" />
              <h2>BBN Play</h2>
              <h5>Welcome to BBN Play</h5>
            </div>
             
            
          </div>
          <div className="offcanvas-body">
            <div className="sidebar-content">
              <ul className="link-section">
                <li>
                  <Link to="/" className="pages">
                    <i className="sidebar-icon" data-feather="home"  />
                    <h3>Home</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/deposite" className="pages">
                    <i className="sidebar-icon" data-feather="dollar-sign" />
                    <h3>Recharge</h3>
                  </Link>
                </li>
                {/* <li>
                  <Link to="page-list.html" className="pages">
                    <i className="sidebar-icon" data-feather="file-text" />
                    <h3>Theme Pages</h3>
                  </Link>
                </li> */}
                <li>
                  <Link to="/network" className="pages">
                    <i className="sidebar-icon" data-feather="command" />
                    <h3>My Brokrage Income</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="pages">
                    <i className="sidebar-icon" data-feather="command" />
                    <h3>Team</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="pages">
                    <i className="sidebar-icon" data-feather="command" />
                    <h3>About BBN Token</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/bonus" className="pages">
                    <i className="sidebar-icon" data-feather="command" />
                    <h3>Betting Bonus</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/profile" className="pages">
                    <i className="sidebar-icon" data-feather="user" />
                    <h3>Profile</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pages">
                    <i className="sidebar-icon" data-feather="user" />
                    <h3>About us</h3>
                  </Link>
                </li>
                <li>
                <Link to="#" onClick={handleLogout} className="pages">
                    <i className="sidebar-icon" data-feather="log-out" />
                    <h3>Log out</h3>
                  </Link>
                </li>
              </ul>
              
            </div>
          </div>
        </div>
      <header className="section-t-space">
      
        <div className="custom-container" style={{marginTop:'10px'}}>
          <div className="header-panel">
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <i className="menu-icon" data-feather="menu" />
            </button>
            <img className="img-fluid logo" src={logo} alt="logo" />
            <div style={{padding:'65px 0 0 145px' , position:'absolute',color:'#780c69' }}><b>BBN PLAY</b></div>
            {/* <div style={{color:'green'}}>BBN Live Price: <br /> {bbnPrice && bbnPrice.rate ? (
        <span>${parseFloat(bbnPrice.rate).toFixed(8)}</span>
    ) : (
        'Loading...'
    )}</div>       */} </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;

                 
                