import React from 'react';
import ReactDOM from 'react-dom/client';
import { useParams } from 'react-router-dom';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Footer from './footer';
import Sidebar from './Sidebar';
import Splash from './Pages/Splash';
import Signin from './Pages/Signin';
import Signup from './Pages/Signup';
import About from './About';
import Notification from './notification';
import Blog from './blog';
import Blog_details from './blog_details';
import Register from './register';
import Login from './login';
import Forget_password from './forget_password';
import OTP from './otp';
import Reset_password from './reset_password';
import Profile from './profile';
import Account from './account';
import FAQ from './faq';
import Change_password from './change_password';
import OTP1 from './otp1';
import Reset_password1 from './reset_password1';
import MyModal from './mymodal';
import Modal from './modal';
import Demo from './demo';
import Game from './game';
import Deposite from './deposite';
import Withdraw from './withdraw';
import Withdraw_report from './withdraw_report';
import Deposite_report from './deposite_report';
import Game1 from './game1';
import Game5 from './game5';
import Network from './network';
import Team from './team';
import Bonus from './bonus';
import UpdateUPI from './updateupi';
import OTP2 from './otp2';
import Update_upi from './update_upi';
import Transfer from './transfer';
import WinPopup from './winpopup';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/About" element={<About />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/account" element={<Account />} />
        <Route path="/game" element={<Game />} />
        <Route path="/game1" element={<Game1 />} />
        <Route path="/game5" element={<Game5 />} />
        <Route path="/network" element={<Network />} />
        <Route path="/deposite" element={<Deposite />} />
        <Route path="/deposite_report" element={<Deposite_report />} />
        <Route path="/bonus" element={<Bonus />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw_report" element={<Withdraw_report />} />
        <Route path="/team" element={<Team />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog_details/:id" element={<Blog_details />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/updateupi" element={<UpdateUPI />} />
        <Route path="/forget_password" element={<Forget_password />} />
        <Route path="/change_password" element={<Change_password />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/otp1" element={<OTP1 />} />
        <Route path="/otp2" element={<OTP2 />} />
        <Route path="/reset_password" element={<Reset_password />} />
        <Route path="/reset_password1" element={<Reset_password1 />} />
        <Route path="/update_upi" element={<Update_upi />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/Sidebar" element={<Sidebar />} />
        <Route path="/Splash" element={<Splash />} />
        <Route path="/Signin" element={<Signin />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/mymodal" element={<MyModal />} />
        <Route path="/winpopup" element={<WinPopup />} />
        <Route path="/modal" element={<Modal />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/transfer" element={<Transfer />} />

      </Routes>
    </Router>
  </React.StrictMode>
);
