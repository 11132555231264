import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';
import facebook from './assets/theme_asset/svg/facebook.svg';
import google from './assets/theme_asset/svg/google.svg';


import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';
import auth1 from './assets/theme_asset/authentication/1.svg';
import auth3 from './assets/theme_asset/authentication/3.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";




function Reset_password() {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const email = queryParams.get('email');
    const [formData, setFormData] = useState({
      email: email,
      password: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        localStorage.setItem('modalMessage3', JSON.stringify({
          title: 'Password Changed Successfully',
          content: 'Login with new password',
        }));
        await axios.post(`${apiBaseUrl}/reset_password`, formData);
        navigate('/login');
      } catch (error) {
        setModalMessage({
          title: 'Error',
          content: 'Some thing went wrong.Please enter correct password and try again later.',
        });
        setShowModal(true);
      }
    };
    
    const [modalMessage2, setModalMessage] = useState(null);
  
    useEffect(() => {
      // Retrieve modal message from local storage
      const storedMessage = localStorage.getItem('modalMessage2');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage2');
      }
    }, []);
  
    
  
    return (
      <body className="">
      <div>
        {modalMessage2 && (
          <Modal message={modalMessage2} closeModal={() => setModalMessage(null)} />
        )}
        <div className="auth-header">
        <div className="custom-container">
        <div className='row'> 
          <div className='col-2'>
        <Link to="/" style={{ textDecoration: 'none',marginLeft:'10px',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 100px', fontSize:'15px',color:'white'}}><img src={logo} alt='logo' style={{width:'55px', height:'45px', marginLeft:'5px' }}/><br /><b>BBN Play</b></div>
    </div></div>
          
          <div className="auth-content">
            <div>
              <h2>Welcome To BBN Play !!</h2>
              <h4 className="p-0">Fill up the form</h4>
            </div>
          </div>
        </div>
  
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="custom-container">
           
            <div className="form-group mb-2">
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <div className="form-input">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter Your password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>
  
            <button type="submit" className="btn theme-btn w-100">
              Reset Password
            </button>
          </div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <br/><br/><br/><br/><br/>
          
        </form>
      </div>
      </body>
    );
  }
  
  export default Reset_password;