import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';
import facebook from './assets/theme_asset/svg/facebook.svg';
import google from './assets/theme_asset/svg/google.svg';


import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';
import auth1 from './assets/theme_asset/authentication/1.svg';
import auth3 from './assets/theme_asset/authentication/3.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import Modal from "./modal";







function UpdateUPI() {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.setItem('modalMessage2', JSON.stringify({
        title: 'BBN Address Reset Request Sent',
        content: 'BBN Address reset request sent successfully. Check your email for OTP.',
      }));

      const formDataToSend = new FormData();
      formDataToSend.append('email', email);

      const response = await axios.post(`${apiBaseUrl}/forgotpassword`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      

      // Redirect to the login page after successful password reset request
      const url = `/otp2?email=${encodeURIComponent(email)}`; 
      navigate(url);
    } catch (error) {
      setModalMessage({
        title: 'Error',
        content: 'Failed to send BBN Address reset request. Please try again later or check the email you entered.',
      });
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  
  return (
    <body className=''>
    <div>
      {/* Render Modal */}
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
    
      <div className="auth-header">
      <div> <div className="custom-container">
      <div className='row'> 
          <div className='col-2'>
        <Link to="/profile" style={{ textDecoration: 'none',marginLeft:'10px',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 100px', fontSize:'15px',color:'white'}}><img src={logo} alt='logo' style={{width:'55px', height:'45px', marginLeft:'5px' }}/><br /><b>BBN Play</b></div>
    </div></div>
            </div>
        
       
        <div className="auth-content">
          <div>
            <h2>Change your BBN Address</h2>
            <h4 className="p-0">Don’t worry !</h4>
          </div>
        </div>
      </div>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="custom-container">
          <div className="form-group mb-2">
            <h5>To reset your BBN Address, enter your registered email Id.</h5>
            <label htmlFor="inputusername" className="form-label">
              Email 
            </label>
            <div className="form-input">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter Your Email ID"
                
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit" className="btn theme-btn w-100" >
            Update password
          </button>
        </div>
      </form>
    </div>
    </body>
  );
}
export default UpdateUPI;
